import React from "react";
import Navbar from "../../components/Navbar/Navbar";
import PageTitle from "../../components/pagetitle/PageTitle";
import Footer from "../../components/footer/Footer";
import Scrollbar from "../../components/scrollbar/scrollbar";

import SilverVillas from "../../components/projectsComp/table/SilverVillas";
import PlanHeading from "../../components/projectsComp/PlanHeading";
import VillasDetails from "../../components/projectsComp/VillasDetails";
import SilverVillaDis from "../../components/projectsComp/silverVillas/SilverVillaDis";
import DownloadPlan from "../../components/projectsComp/DownloadPlan";
import { Helmet } from "react-helmet";
import { metaData } from "../../api/metaData";
import SilverSection from "../../components/projectsComp/silverVillas/SilverSection";
// import LayoutPlan from "../../components/projectsComp/LayoutPlan";

const SilverVilla = () => {
  const page = "Svillas";
  const { title, description } = metaData(page);
  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
      </Helmet>
      <Navbar />
      <PageTitle pageTitle={"Silver Villas"} />
      <SilverVillaDis />
      <VillasDetails />
      <div className=" mt-135">
        <SilverSection />
      </div>

      <PlanHeading />

      <SilverVillas />
      <DownloadPlan />
      {/* <LayoutPlan /> */}
      <Footer />
      <Scrollbar />
    </>
  );
};

export default SilverVilla;
