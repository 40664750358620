import React from "react";
import Navbar from "../../components/Navbar/Navbar";
import PageTitle from "../../components/pagetitle/PageTitle";
import VillasDetails from "../../components/projectsComp/VillasDetails";
import PlanHeading from "../../components/projectsComp/PlanHeading";
import Footer from "../../components/footer/Footer";
import Scrollbar from "../../components/scrollbar/scrollbar";
import GoldenVillas from "../../components/projectsComp/table/GoldenVillas";
import DownloadPlan from "../../components/projectsComp/DownloadPlan";
import { metaData } from "../../api/metaData";
import { Helmet } from "react-helmet";
import GoldenVillaDis from "../../components/projectsComp/goldenVillas/GoldenVillaDis";

import GoldenSection from "../../components/projectsComp/goldenVillas/GoldenSection";
// import LayoutPlan from "../../components/projectsComp/LayoutPlan";
const GoldenVilla = () => {
  const page = "Gvillas";
  const { title, description } = metaData(page);
  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
      </Helmet>
      <Navbar />
      <PageTitle pageTitle={"Golden Villas"} />
      <GoldenVillaDis />
      <VillasDetails />
      <div className=" mt-135">
        <GoldenSection />
      </div>
      <PlanHeading />
      <GoldenVillas />
      <DownloadPlan />
      {/* <LayoutPlan /> */}
      <Footer />
      <Scrollbar />
    </>
  );
};

export default GoldenVilla;
